.wrapper {
  position: fixed;
  top: 60px;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: auto;
  padding: 1rem 0 3rem;
  overscroll-behavior: contain;
}

.combinedLine {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 0 2rem;
  cursor: default;
}

.combinedLine:hover {
  background: rgba(0, 0, 0, 0.05);
}

.line {
  white-space: nowrap;
}

.clause,
.lineNumber {
  font-family: monospace;
  font-size: 1rem;
  color: black;
}

.lineNumber {
  width: 40px;
  color: lightgrey;
}

.line,
.lineDescription {
  flex: 1;
  overflow: hidden;
}

.comment {
  color: grey;
}

.command {
  color: #f06;
}

.parameter {
  color: blue;
}

.parameter.mm.relative {
  color: #00a3ff;
}

.parameter.inch.absolute {
  color: #009420;
}

.parameter.in.relative {
  color: #11d100;
}

.directive {
  color: #aa0;
}

.command,
.directive {
  font-weight: bold;
}

.command.hovered,
.directive.hovered {
  background: black;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 14px;
  font-family: BlinkMacSystemFont, sans-serif;
}

.emptyState {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
}

.emptyState h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.load {
  background: black;
  height: 40px;
  width: 343px;
  border-radius: 4px;
  display: block;
  line-height: 20px;
  padding: 5px;
  border: none;
  color: white;
  font: inherit;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin: 1.5rem 0 3rem;
}

.privacy {
  color: #8a8a8a;
}

.privacy a {
  color: inherit;
  text-decoration: underline;
}

.privacy a:hover {
  text-decoration: none;
}
